<template>
  <div class="import">
    <div class="btnBox">
      <el-tooltip effect="dark" content="导入文件格式为xlsx" placement="top">
        <el-button type="primary" class="_ImgBtn" @click="uploadFile">
          <img src="../../assets/images/import2.png" />
          导入题库
        </el-button>
      </el-tooltip>
    </div>
    <input
      type="file"
      @change="importFile(this)"
      id="imFile"
      ref="fileBtn"
      style="display: none"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
    <div class="tableBox" v-loading="loading">
      <el-table :data="tableList" id="table" border height="100%">
        <el-table-column prop="CompName" label="公司" width="180"></el-table-column>
        <el-table-column prop="DeptName" label="部门"></el-table-column>
        <el-table-column prop="PostName" label="岗位"></el-table-column>
        <el-table-column prop="ProcessName" label="过程"></el-table-column>
        <el-table-column prop="FunctionName" label="职能"></el-table-column>
        <el-table-column prop="FileNo" label="文件编号"></el-table-column>
        <el-table-column prop="FileName" label="文件名称"></el-table-column>
        <el-table-column prop="Question" label="检查内容"></el-table-column>
        <el-table-column prop="Answer" width="380" label="标准答案"></el-table-column>
        <el-table-column prop="Version" label="版本号" width="40"></el-table-column>
        <el-table-column prop="Module" label="模块"></el-table-column>
        <el-table-column prop="StandardAbbr" label="标准"></el-table-column>
        <el-table-column prop="RiskPoints" label="风险点"></el-table-column>
        <el-table-column prop="RiskMeasures" label="风险应对措施"></el-table-column>
        <el-table-column prop="ExamQuestion" label="判断题（答案是）"></el-table-column>
        <el-table-column prop="DisturbQuestion" label="判断题（答案否）"></el-table-column>
        <el-table-column prop="Interpretation" label="应知应会"></el-table-column>
        <el-table-column prop="ChoiceQue" label="选择题"></el-table-column>
        <el-table-column prop="ChoiceQueOption" label="选择题答案"></el-table-column>
        <el-table-column prop="ChoiceQueAnswer" label="选择题正确答案"></el-table-column>
        <el-table-column prop align="center" label="检查类别">
          <el-table-column prop label="1">
            <el-table-column prop="AreaAudit" label="现场检查">
              <template slot-scope="scope">
                <div>{{ scope.row.AreaAudit ? "√" : "" }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="2">
            <el-table-column prop="FileAudit" label="文件检查">
              <template slot-scope="scope">
                <div>{{ scope.row.FileAudit ? "√" : "" }}</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="IsCurrency" label="通用">
          <template slot-scope="scope">
            <div>{{ scope.row.IsCurrency ? "√" : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="IsFuncCurrency" label="职能通用">
          <template slot-scope="scope">
            <div>{{ scope.row.IsFuncCurrency ? "√" : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="IsSpecial" label="专指">
          <template slot-scope="scope">
            <div>{{ scope.row.IsSpecial ? "√" : "" }}</div>
          </template>
        </el-table-column>

        <el-table-column prop align="center" label="所在区域">
          <el-table-column prop="arrArea" :label="index+1+''" v-for="(item,index) in areaList" :key="index">
            <el-table-column :label="item.areaName">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.arrArea.split(",").includes(item.areaName) ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <!-- <el-table-column prop="arrArea" label="1">
            <el-table-column label="厂区区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("厂区区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="2">
            <el-table-column prop="arrArea" label="车间区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("车间区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="3">
            <el-table-column prop label="仓库区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("仓库区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="4">
            <el-table-column prop label="实验室区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("实验室区域")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="5">
            <el-table-column prop label="办公区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("办公区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="6">
            <el-table-column prop label="饭堂区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("饭堂区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="7">
            <el-table-column prop label="宿舍区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("宿舍区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="8">
            <el-table-column prop label="工程区域">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrArea.split(",").includes("工程区域") ? "√" : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column> -->
        </el-table-column>
        <el-table-column prop align="center" label="检查范畴">
          <el-table-column prop="" :label="index + 1 + ''" v-for="(item,index) in auditTypeList" :key="index">
            <el-table-column prop="" :label="item.auditTypeName">
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                      scope.row.arrAuditType.split(",").includes(item.auditTypeName)
                        ? "√"
                        : ""
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <!-- <el-table-column prop label="1">
            <el-table-column prop label="安全生产">
              <template slot-scope="scope">
                <div>
                  <div>
                    {{
                    scope.row.arrAuditType.split(",").includes("安全生产")
                    ? "√"
                    : ""
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="2">
            <el-table-column prop label="领导干部现场带班">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("领导干部现场带班")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="3">
            <el-table-column prop label="三违行为管理">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("三违行为管理")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="4">
            <el-table-column prop label="班组岗位达标安全">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("班组岗位达标安全")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="5">
            <el-table-column prop label="安全教育作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("安全教育作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="6">
            <el-table-column prop label="交叉作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("交叉作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="7">
            <el-table-column prop label="相关方施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("相关方施工安全")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="8">
            <el-table-column prop label="特种作业人员管理">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("特种作业人员管理")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="9">
            <el-table-column prop label="特种设备管理">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("特种设备管理")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="10">
            <el-table-column prop label="危险作业工作票作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("危险作业工作票作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="11">
            <el-table-column prop label="动火作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("动火作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="12">
            <el-table-column prop label="高空作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("高空作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="13">
            <el-table-column prop label="吊装作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("吊装作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="14">
            <el-table-column prop label="有限空间作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("有限空间作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="15">
            <el-table-column prop label="搬运安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("搬运安全作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="16">
            <el-table-column prop label="电焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("电焊机安全作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="17">
            <el-table-column prop label="气焊机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("气焊机安全作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="18">
            <el-table-column prop label="砂轮机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("砂轮机安全作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="19">
            <el-table-column prop label="手持电动工具安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("手持电动工具安全作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="20">
            <el-table-column prop label="起重机安全作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("起重机安全作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="21">
            <el-table-column prop label="液氨泄漏探测仪检测作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("液氨泄漏探测仪检测作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="22">
            <el-table-column prop label="液氨使用作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("液氨使用作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="23">
            <el-table-column prop label="涉水作业">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("涉水作业")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="24">
            <el-table-column prop label="危险化学品">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("危险化学品")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="25">
            <el-table-column prop label="一般化学品">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("一般化学品")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="26">
            <el-table-column prop label="安全用电">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("安全用电")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="27">
            <el-table-column prop label="临时用电">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("临时用电")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="28">
            <el-table-column prop label="职业健康安全">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("职业健康安全")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="29">
            <el-table-column prop label="劳动防护">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("劳动防护")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="30">
            <el-table-column prop label="施工安全">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("施工安全")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="31">
            <el-table-column prop label="消防设施">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("消防设施")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="32">
            <el-table-column prop label="消防设备">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("消防设备")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="33">
            <el-table-column prop label="疏散指示">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("疏散指示")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="34">
            <el-table-column prop label="特种设备">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("特种设备")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="35">
            <el-table-column prop label="机械设备">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("机械设备")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="36">
            <el-table-column prop label="投加设备">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("投加设备")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="37">
            <el-table-column prop label="高低压电柜">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("高低压电柜")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="38">
            <el-table-column prop label="一般设备">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("一般设备")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="39">
            <el-table-column prop label="作业环境">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("作业环境")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="40">
            <el-table-column prop label="办公环境">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("办公环境")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="41">
            <el-table-column prop label="实验室环境">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("实验室环境")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="42">
            <el-table-column prop label="车间环境">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("车间环境")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="43">
            <el-table-column prop label="仓库环境">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("仓库环境")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="44">
            <el-table-column prop label="宿舍环境">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("宿舍环境")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="45">
            <el-table-column prop label="应急物资">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("应急物资")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="46">
            <el-table-column prop label="应急设备">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("应急设备")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="47">
            <el-table-column prop label="应急预案">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("应急预案")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="48">
            <el-table-column prop label="应急演练">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("应急演练")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="49">
            <el-table-column prop label="7S管理">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("7S管理")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="50">
            <el-table-column prop label="设备状态">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("设备状态")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="51">
            <el-table-column prop label="安全警示标识">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("安全警示标识")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="52">
            <el-table-column prop label="作业指导书标识">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("作业指导书标识")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="53">
            <el-table-column prop label="行为标识">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("行为标识")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="54">
            <el-table-column prop label="信息栏">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("信息栏")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="55">
            <el-table-column prop label="卫生用品存放">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("卫生用品存放")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="56">
            <el-table-column prop label="四色图">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("四色图")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="57">
            <el-table-column prop label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("疫情防控")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="58">
            <el-table-column prop label="管道安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("管道安装工程质量")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="59">
            <el-table-column prop label="疫情防控">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("疫情防控")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="60">
            <el-table-column prop label="构筑物工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("构筑物工程质量")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="61">
            <el-table-column prop label="道路工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType.split(",").includes("道路工程质量")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="62">
            <el-table-column prop label="设备安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("设备安装工程质量")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="63">
            <el-table-column prop label="机电安装工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("机电安装工程质量")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop label="64">
            <el-table-column prop label="水环境治理工程质量">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.arrAuditType
                  .split(",")
                  .includes("水环境治理工程质量")
                  ? "√"
                  : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column> -->
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { get,post } from "../../api/http";
import XLSX from "xlsx";
import { exportExcel } from "../../modules/ExportExcel";
export default {
  data() {
    return {
      tableList: [],
      loading: false,
      areaList:[],
      auditTypeList:[],
    };
  },
  mounted() {
    this.getdate();
    this.imFile = document.getElementById("imFile");
  },
  methods: {
    getdate() {
      get("/api/AuditType/GetList").then((res) => {
        this.auditTypeList = res.data.filter(item => item.upId !== 0);
      });
      get("/api/Area/GetList").then((res) => {
        this.areaList = res.data.filter((item, index, self) => {
          return index === self.findIndex(obj => obj.areaName === item.areaName);
        });
      });
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.tableList = [
            {
              CompName: "公司名称",
              DeptName: "部门名称",
              PostName: "岗位名称",
              ProcessName: "过程名称",
              FunctionName: "职能名称",
              FileNo: "文件编号",
              FileName: "文件名称",
              Question: "格式无限制",
              Version: "格式无限制",
              Module:"模块名字",
              Answer: "格式无限制",
              StandardAbbr: "标准设置页面中（填写标准简称）",
              RiskPoints: "格式无限制",
              RiskMeasures: "格式无限制",
              ExamQuestion: "是",
              DisturbQuestion: "否",
              Interpretation: "格式无限制",
              ChoiceQue: "格式无限制",
              ChoiceQueOption: "格式无限制",
              ChoiceQueAnswer: "选择题正确答案",
              IsCurrency: "√",
              IsFuncCurrency: "√",
              IsSpecial: "√",
              AreaAudit: "√",
              FileAudit: "√",
              arrArea: "",
              arrAuditType: "",
            },
            {
              CompName:"备注（所在区域与检查范畴填写对应的区域名称、范畴名称即可）",
              arrArea: "",
              arrAuditType: "",
            },
          ];
          if (this.tableList.length != 0) {
            this.$nextTick(() => {
              exportExcel("#table", "题库导入.xlsx");
              this.tableList = [];
            });
          }
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      this.tableList = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        console.log(excelData);
        excelData = excelData.slice(2);
        excelData.map((item) => {
          console.log(item,'item');
          // const emptyFields = [
          //   "__EMPTY_1",
          //   "__EMPTY_2",
          //   "__EMPTY_3",
          //   "__EMPTY_4",
          //   "__EMPTY_5",
          //   "__EMPTY_6",
          //   "__EMPTY_7",
          // ];
          const emptyFields = [];
          $this.areaList.forEach((item,index) =>{
            emptyFields.push(`__EMPTY_${index+1}`)
          })
          emptyFields.pop();
          item.所在区域 = emptyFields.reduce((acc, field) => {
            if (item[field]) {
              acc += "," + item[field];
            }
            return acc;
          }, item.所在区域);
         
          const emptyCategory = Array.from(
            { length: $this.auditTypeList.length - 1 },
            (_, i) => `__EMPTY_${i + $this.areaList.length}`
          );
          item.检查范畴 = emptyCategory.reduce((acc, field) => {
            if (item[field]) {
              acc += "," + item[field];
            }
            return acc;
          }, item.检查范畴);
          
          var data = {
            CompName: item.公司,
            DeptName: item.部门,
            PostName: item.岗位,
            ProcessName: item.过程,
            FunctionName: item.职能,
            FileNo: item.文件编号,
            FileName: item.文件名称,
            Question: item.检查内容,
            Answer: item.标准答案,
            Version: item.版本号?item.版本号 + '' :'',
            Module: item.模块,
            StandardAbbr: item.标准,
            RiskPoints: item.风险点,
            RiskMeasures: item.风险应对措施,
            ExamQuestion: item["判断题（答案是）"],
            DisturbQuestion: item["判断题（答案否）"],
            Interpretation: item.应知应会,
            ChoiceQue: item.选择题,
            ChoiceQueOption: item.选择题答案,
            ChoiceQueAnswer: item.选择题正确答案,
            IsCurrency: item.通用 ? 1 : 0,
            IsFuncCurrency: item.职能通用 ? 1 : 0,
            IsSpecial: item.专指 ? 1 : 0,
            AreaAudit: item.检查类别 ? 1 : 0, //现场检查
            FileAudit: item.__EMPTY ? 1 : 0, //文件检查
            arrArea: item.所在区域 ? item.所在区域.replace(/^undefined,/, '') : "",
            arrAuditType: item.检查范畴 ? item.检查范畴.replace(/^undefined,/, '') : "",
          };
          $this.tableList.push(data);
          console.log($this.tableList, 9527);
        });
        if ($this.tableList.length != 0) {
          $this.importQuestion($this.tableList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null;
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },

    async importQuestion(list) {
      await post("/api/QuesPoolAndExam/CheckData", {
        data: JSON.stringify(list),
      }).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "验证成功,开始导入",
          });
          list.forEach((item, index) => {
            this.importData(item, index);
          });
        } else {
          this.$message.error(resp.message);
        }
        this.$refs.fileBtn.value = null;
      });
    },

    async importData(item, index) {
      this.loading = true;
      await post("/api/QuesPoolAndExam/BatchAdd", item)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message({
              type: "error",
              message:
                ("1)第" + index + 1 + "行导入失败",
                "2)错误行数：" + item,
                "3)错误原因：" + resp.message),
            });
            this.loading = false;
            return;
          }
          if (index + 1 == this.tableList.length) {
            this.loading = false;
            this.$message({
              type: "success",
              message: "已全部导入",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.import {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  .btnBox {
    text-align: end;
    margin-bottom: 5px;
  }
  .tableBox {
    flex: 1;
    ::v-deep .el-table th.el-table__cell {
      background: #ffffff !important;
    }
  }
}
</style>
